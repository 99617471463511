@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.footer {
  margin-top: 60px;
  background-color: $white;
  &__container {
    padding: 40px 20px 20px 20px;
  }
  &__items {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 29px;
    a {
      text-decoration: none;
    }
  }
  &__title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 15px;
    &--grability {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 13px;
    }
  }
  &__grability {
    padding: 15px 0;
    text-align: center;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(91, 91, 91, 0.75);
    -moz-box-shadow: 0px 0px 1px 0px rgba(91, 91, 91, 0.75);
    box-shadow: 0px 0px 1px 0px rgba(91, 91, 91, 0.75);
  }
  &__social {
    margin: 0px 55px 0 0;
  }
  &__img-footer {
    @media screen and (max-width: $smartphone) {
      max-height: 30px;
      object-fit: contain;
      object-position: left;
    }
  }
  &__bvc {
    max-width: 100px;
    @media screen and (max-width: $smartphone) {
      max-width: 120px;
    }
  }
  &__support {
    margin-bottom: 20px;
    @media screen and (max-width: $smartphone) {
      max-width: 250px;
      margin-bottom: 20px;
    }
    &__bid-lab-container {
      @media screen and (max-width: $smartphone) {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
      }
    }
    &--bid {
      margin-bottom: 20px;
      border-right: 1px solid $middle-gray;
      @media screen and (max-width: $smartphone) {
        max-width: 200px;
        margin-bottom: 20px;
      }
    }
    &--lab {
      margin-bottom: 20px;
      @media screen and (max-width: $smartphone) {
        max-width: 130px;
        margin-bottom: 20px;
      }
    }
  }
}
