@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.header {
  justify-content: center !important;

  .header_custom{
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 125px;
    }
  }
}

.navbar {
  font-weight: 600;
  height: 70px;
  padding: 25px 0;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
  -moz-box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
  box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
  @media screen and (max-width: $smartphone) {
    padding: 32px 0;
  }
  &--logo {
    height: 25px;
    width: 127px;
  }
  &__drawer--logo {
    height: 26px;
    width: 135px;
  }
  &__drawer--register {
    height: 18px;
    width: 22px;
    margin-right: 18px;
  }
  &__drawer--login {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }
  &__a--logo {
    margin-bottom: 50px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $smartphone) {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }
  }
  &__sticky {
    position: fixed;
    z-index: 99;
    width: 100%;
    @media screen and (max-width: $smartphone) {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__buttons {
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 350px;
    align-items: center;
    @media screen and (max-width: $smartphone) {
      display: none;
    }
  }
  &__button--turquoise {
    width: 160px;
    padding: 9px 0;
    border-color: $bright-turquoise;
    border-width: 1px;
    color: $bright-turquoise;
  }

  &__button--fill__blue {
    width: 174px;
    padding: 9px 0;
    border-color: $bright-turquoise;
    border-width: 1px;
    color: $white;
    background-color: $bright-turquoise;
  }
}

.bm-burger-button {
  position: fixed;
  @media screen and (max-width: $smartphone) {
    position: fixed;
    width: 25px;
    height: 20px;
    left: 23px;
    top: 36px;
  }
}

.bm-burger-bars {
  background: #373a47;
}

.bm-burger-bars-hover {
  background: #a90000;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  top: 0px;
  position: fixed;
  height: 100vh;
}

.bm-menu {
  height: 100vh !important;
  background: $white;
  padding: 2.5em 0em;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-item {
  display: flex !important;
  align-items: center;
  text-decoration: none;
  color: $tuatara;
  padding: 10px 0px;
  font-weight: bold;
  padding-left: 1.5em;
  &:hover {
    background-color: $bright-turquoise-transparent;
  }
}

.bm-overlay {
  top: 0px;
  height: 100vh !important;
  background: rgba(0, 0, 0, 0.5);
}

.navbar__buttons {
  text-align: center;
  display: flex;
  align-items: center;
  width: 85%;
  margin: 0 15px;
}

.navbar__link-page {
  margin: 0px 15px;  
  color: #007bff;
  font-family: Roboto !important;
}

.navbar-light .navbar-nav .nav-link, .navbar-light .dropdown-item{
  text-align: center;
  color: #007bff !important;
}

.navbar-light .navbar-brand {
  padding: 0 0 .3125rem 0 !important;
  margin-right: 1rem;
  margin-left: 1rem !important;
}

.navbar-light .navbar-collapse {
  background-color: white;
}

.header_custom {
  min-width: 48rem;
}
