$bright-turquoise: #0bbbef;
$bright-turquoise-transparent: #B7E4F5;
$congress-blue: #004997;
$tuatara: #31302f;
$spring-green: #39fbbf;
$cornflower-blue: #6262f9;
$transparent-gray: rgba(200, 200, 201, 0.2);
$transparent-white: rgba(255, 255, 255, 0.6);
$middle-gray: rgb(166, 166, 169);
$gray: #f5f6f8;
$description-gray: #6d7278;
$dark-gray: #babdca;
$white: #ffffff;
$bright-blue: #ddf1f8;
$red: #BE6464;
$red-fc: #FF317B;
$congress-turquoise: #0BBEF0;
$dark-blue: #004997;  
$light-gray: rgba(219,220,221,0.44);
$dark-blue: #2C5697;
