@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.__react_component_tooltip.type-error {
  color: #fff;
  background-color: $red;
}
.__react_component_tooltip.type-error.place-left:after {
  border-left-color: $red;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-error.place-bottom:after {
  border-bottom-color: $red;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.tooltip_position{
  position: absolute;
  right: 4%;
}

.input_label {
  margin: 30px;
}

.graytext-input{
  width: -webkit-fill-available;
  border: none;
  background: lightgray;
  margin: 5%;
  padding: 3% 5%;
  border-radius: 10px;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__field {
    background: #eff0f0;
    border-radius: 20px;
    flex: 1;
    border: none;
    padding: 20px 30px;
    color: $tuatara;
    font-family: Nunito;
    font-size: 19px;
    outline: none;
    width: 100%;

    @media screen and (max-width: $smartphone) {
      padding: 15px 10px;
      font-size: 13px;
    }

    &-icon {
      padding-right: 25%;
    }
  }

  &__show-password {
    width: 30px;
    height: 22px;
    &-button {
      padding: 0px;
      position: absolute;
      right: 20%;
      border: 0px;
      background-color: transparent;
    }
  }

  &__hide-password {
    width: 32px;
    height: 24px;
    &-button {
      padding: 0px;
      position: absolute;
      right: 19.5%;
    }
  }

  &__show-icon {
    width: 30px;
    height: 22px;
    padding: 0px;
    position: absolute;
    right: 17%;
    border: 0px;
    background-color: transparent;
    &-img {
      width: 20px;
      height: 20px;
      vertical-align: initial;
    }
  }

  &__tooltip-logo-container {
    padding: 0 15px 0 10px;

    @media screen and (max-width: $desktop) {
      padding: 0 10px 0 10px;
    }
  }

  &__tooltip_logo {
    width: 20px;
    height: 20px;

    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
  &__tooltip_logo_select {
    width: 20px;
    height: 20px;
    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
}

.error-tooltip {
  &__back {
    opacity: 1 !important;
    background-color: $dark-gray;
  }

  &__container {
    width: 170px;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    align-items: flex-start;
  }

  &__text {
    &--title {
      color: $white !important;
      font-family: Nunito;
      font-size: 12px !important;
      margin: 2px !important;
      font-weight: 900;
      line-height: 16px !important;
    }
  }
}
