@import "../../styles/colors.scss";

.button {
  padding: 8px 0;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  background-color: $white;
  border: none;
  border-radius: 22.5px;
  font-size: 17px;
  color: $bright-turquoise;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &--transparent {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
  }
  &--transparent-blue {
    background-color: transparent;
    border: 2px solid $bright-turquoise;
    color: $bright-turquoise;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    -webkit-box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    -moz-box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    transform: scale(1.02);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  
    &:hover {
      box-shadow: 0px 0px 0px 0px;
      transform: none;
    }
  }
}
