@import "../../styles/colors";
@import "../../styles/breakpoints.scss";

.register {
  &__container {
    width: 100%;
    padding-bottom: 10px;
  }

  &__previous {
    &__button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      margin-top: 25px;

      @media screen and (max-width: $smartphone) {
        margin-top: 20px;
      }
    }

    &__image {
      height: 22px;
      width: 22px;
      object-fit: contain;
    }
  }
}

.draw {
  margin-top: 30px;
  margin-left: 15px;

  &__circle {
    height: 15px;
    width: 15px;
    background-color: $bright-turquoise;
    border-radius: 50%;
  }

  &__line {
    height: 25px;
    width: 1px;
    margin-left: 7px;
    background-color: $bright-turquoise;

    @media screen and (max-width: $smartphone) {
      height: 16px;
    }
  }
}

.form {
  &__title {
    color: $tuatara;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 30px;

    @media screen and (max-width: $smartphone) {
      font-size: 13px;
      margin: 20px 15px;
    }
  }

  &__container {
    background-color: $white;
    border-radius: 10.5px;
    overflow: hidden;
  }

  &__checkboxes {
    &__container {
      margin-top: 50px;

      @media screen and (max-width: $smartphone) {
        margin-top: 50px;
      }
    }
  }

  &__checkbox {
    &__container {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__text {
      color: $tuatara;
      font-family: Roboto;
      margin-left: 15px;

      @media screen and (max-width: $smartphone) {
        font-size: 13px;
      }
    }

    &__link {
      color: $bright-turquoise;
    }
  }
}

.continue {
  &__container {
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $smartphone) {
      height: 60px;
    }
  }

  &__button {
    width: 215px;
    height: 49px;
    background-color: $white;
    border-radius: 24px;
    border: solid $bright-turquoise 1px;
    color: $bright-turquoise;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 800;
    outline: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    @media screen and (max-width: $smartphone) {
      width: 170px;
      height: 40px;
      border-radius: 18px;
      font-size: 13px;
    }
  }
}

.b-bottom {
  border-bottom: 1px solid $dark-gray;
}

.b-error {
  border-bottom: 1px solid $red;
}

.b-bottom-small {
  @media screen and (max-width: $smartphone) {
    border-bottom: 1px solid $dark-gray;
  }
}

.b-right {
  border-right: 1px solid $dark-gray;
}

.b-left {
  position: relative;

  &::before {
    content: "";
    height: 100%;
    width: 1px;
    display: block;
    position: absolute;
    top: 15%;
    background-color: $dark-gray;

    @media screen and (max-width: $smartphone) {
      display: none;
    }
  }
}

.b-left-small {
  @media screen and (max-width: $smartphone) {
    border-left: 1px solid $dark-gray;
  }
}

.large_input {
  width: 100%;
}

//--------- Estilo Alert ----
.alert-div {
  position: fixed;
  width: 30%;
  top: 10%;
  left: 0;
  margin: 0% 35%;
  z-index: 100;
  text-align: center;
}